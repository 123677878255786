import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box/Box';
import Integrators from './integrators';
import RealTimeInterception from './real-time-interception';
import Settings from './settings';
import Affiliates from './affiliates';
import DataAndAnalytics from './data-and-analytics';
import PixelGuard from './pixel-guard';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography/Typography';
import { getNetworkServingDomain, getDedicatedWLDomains } from '@redux/thunks/settings';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useContentManager, useSplit } from '@hooks';
import { constants } from '@utils/split.io';
import { LEAD_PROTECTION } from '../../../utils/common';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { useLicenses } from '../../../hooks/use-licenses';
import { licenses } from '../../../utils/settings';
import { isAuthorized } from 'utils/permissions';
import mixpanel from '@services/mixpanel';
import Header from '../header';
import GoogleAnalytics from './google-analytics';
import { HubSpot } from './hubspot';
import { Marketo } from './marketo';
import { SETTINGS_TABS_NAMES, showTab, getSolutionSettings } from './constants';
import { AdobeAnalytics } from './adobe-analytics';
import { TabPanel } from '../../tabs';

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: 0,
        marginTop: 30,
        padding: '24px 100px',
        boxSizing: 'border-box',
        backgroundColor: theme.palette.background.paper,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',

        '& .MuiTab-root.Mui-selected': {
            backgroundColor: theme.palette.background.default,
            color: '#fff',
        },
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.background.default,
        },
        '& .MuiTabs-root': {
            color: theme.palette.custom.text.primary,
            backgroundColor: theme.palette.background.paper,
        },
        '& .MuiTab-root': {
            color: theme.palette.custom.text.primary,
            backgroundColor: theme.palette.background.paper,
            minWidth: '0px !important',
        },
        '& .MuiBox-root': {
            padding: 0,
            backgroundColor: theme.palette.background.paper,
        },
        '& .MuiAppBar-colorDefault': {
            boxShadow: 'none',
        },
        '& .MuiTab-wrapper': {
            fontSize: 14,
        },
    },
}));

const Management = () => {
    const dispatch = useDispatch();
    const { state, pathname } = useLocation();
    const theme = useTheme();

    const classes = useStyles();
    const cm = useContentManager();
    const { permissions, meta: { userRole } = {} } = useSelector(state => state.profile);
    const { isAffiliateFraudEnabled: isShowAffiliateTab, isGAConnectorEnabled, pxgVersion, dedicatedWLDomains, selectedLicense } = useSelector(state => state.settings);
    const [tab, setTab] = React.useState(getSolutionSettings()[selectedLicense][0]);
    const [tabIndex, setTabIndex] = React.useState(0);
    const { isOn: isCEGOpen } = useSplit(constants.SPLIT_CONVERSION_EVENT_GUARD);
    const { isOn: isApiIntegrationOn } = useSplit(constants.SPLIT_DATA_AND_ANALYTICS_API_INTEGRATION);
    const { isOn: isHubSpotFeatureOn } = useSplit(constants.PARADOME_HUB_SPOT_FEATURE);
    const { isOn: isMarketo } = useSplit(constants.PARADOME_MARKETO);
    const { isOn: useSettingsV2 } = useSplit(constants.PARADOME_USER_SETTINGS_V2);
    const { isOn: isAdobe } = useSplit(constants.PARADOME_ADOBE_ANALYTICS);
    const { hasDataAndAnalytics, hasPaidMarketing, hasPrivacy, hasOnSiteConversion } = useLicenses();
    const servingDomain = useSelector(state => state.settings.servingDomain);
    const { isOn: isDataApiForExtendedResponseOn } = useSplit(constants.DATA_API_FOR_EXTENDED_RESPONSE);
    const isDataAndAnalyticsOpen = hasDataAndAnalytics && isApiIntegrationOn;
    const isGoogleAnalyticsOpen = hasDataAndAnalytics || (hasPaidMarketing && isGAConnectorEnabled);
    const showPixelGuardTab = pxgVersion === 1 && isCEGOpen && userRole !== 'user' && showTab(SETTINGS_TABS_NAMES.PixelGuard, selectedLicense, isDataApiForExtendedResponseOn);
    const hasOnlyPrivacy = hasPrivacy && !hasPaidMarketing && !hasDataAndAnalytics;

    const tabsProps = getTabsProps(
        (model, action) => isAuthorized(permissions, model, action),
        hasOnSiteConversion,
        isShowAffiliateTab,
        cm,
        showPixelGuardTab,
        isDataAndAnalyticsOpen,
        isGoogleAnalyticsOpen,
        userRole,
        isHubSpotFeatureOn,
        hasOnlyPrivacy,
        useSettingsV2,
        isMarketo,
        isAdobe,
        selectedLicense,
        pathname,
        isDataApiForExtendedResponseOn
    );

    useEffect(
        () =>
            (async () => {
                if (!servingDomain) dispatch(getNetworkServingDomain());
                if (dedicatedWLDomains) dispatch(getDedicatedWLDomains());
                if (performance.navigation.type === 1) {
                    const object = {
                        'Page name': 'Management',
                        'Navigation type': 'Refresh',
                    };

                    mixpanel.postEvent('PageView', object);
                }
            })(),
        []
    );

    useEffect(() => {
        if (state?.isFromRTIInterceptions) {
            const index = tabsProps.findIndex(tab => tab.tabName === SETTINGS_TABS_NAMES.RealTimeInterception);
            setTabIndex(index);
            setTab('RealtimeInterception');
        }
    }, [state]);

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
        setTabIndex(event.target.getAttribute('data-index'));
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        const index = tabsProps.findIndex(tab => tab.tabName === SETTINGS_TABS_NAMES.HubSpot);
        if (code) {
            setTab('HubSpot');
            setTabIndex(index);
        }
    }, []);

    useEffect(() => {
        if (hasOnlyPrivacy) {
            const index = tabsProps.findIndex(tab => tab.tabName === SETTINGS_TABS_NAMES.Settings);
            setTab('Settings');
            setTabIndex(index);
        }
    }, []);

    return (
        <>
            <Header title={cm.get('Settings')} />
            <div
                style={{
                    minHeight: '100%',
                    textAlign: 'left',
                    paddingLeft: 100,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div className={classes.root}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        {tabsProps.map(({ label, dataTestSelector, tabName }, index) => (
                            <Tab key={index} label={label} value={tabName} data-index={index} data-test-selector={`${dataTestSelector}-management-tab`} {...a11yProps(index)} />
                        ))}
                    </Tabs>
                    <TabPanel style={{ flex: 1 }} key={tabIndex} value={tab} index={tabIndex} dir={theme.direction}>
                        {tabsProps?.find(tabProp => tab === tabProp.tabName)?.Panel}
                    </TabPanel>
                </div>
            </div>
        </>
    );
};

const getTabsProps = (
    isAuthorized,
    hasOnSiteConversion,
    isShowAffiliateTab,
    cm,
    showPixelGuardTab,
    isDataAndAnalyticsOpen,
    isGaIntegrationOn,
    userRole = '',
    isHubSpotFeatureOn,
    hasOnlyPrivacyLicense,
    useSettingsV2,
    isMarketo,
    isAdobe,
    selectedLicense,
    pathname,
    isDataApiForExtendedResponseOn
) => {
    const initialTabs = [];

    if (showTab(SETTINGS_TABS_NAMES.Accounts, selectedLicense, isDataApiForExtendedResponseOn))
        initialTabs.push({ Panel: <Integrators />, dataTestSelector: 'accounts', label: cm.get('Accounts'), perm: '', tabName: SETTINGS_TABS_NAMES.Accounts });

    if (!useSettingsV2) initialTabs.push({ Panel: <Settings />, dataTestSelector: 'settings', label: cm.get('Settings'), perm: '', tabName: SETTINGS_TABS_NAMES.Settings });

    const tabs = initialTabs.filter(({ perm }) => !perm || isAuthorized(perm, 'read'));
    if (hasOnlyPrivacyLicense) {
        let tabIndex = tabs.findIndex(tab => tab.dataTestSelector === 'accounts');
        if (tabIndex > -1) {
            tabs.splice(tabIndex, 1);
        }
    }

    showPixelGuardTab && tabs.push({ Panel: <PixelGuard />, dataTestSelector: 'ceg', label: cm.get('CEG'), perm: '', tabName: SETTINGS_TABS_NAMES.PixelGuard });

    isShowAffiliateTab &&
        showTab(SETTINGS_TABS_NAMES.Affiliates, selectedLicense, isDataApiForExtendedResponseOn) &&
        tabs.push({
            Panel: <Affiliates />,
            dataTestSelector: 'affiliates',
            label: cm.get('Affiliates'),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.Affiliates,
        });
    hasOnSiteConversion &&
        userRole !== 'user' &&
        showTab(SETTINGS_TABS_NAMES.RealTimeInterception, selectedLicense, isDataApiForExtendedResponseOn) &&
        tabs.push({
            Panel: <RealTimeInterception networkFeatureId={licenses.OnSiteConversion.id} />,
            dataTestSelector: 'realtime interception',
            label: pathname.includes(LEAD_PROTECTION) ? cm.get('SlpLicenseRtiTabName').toUpperCase() : cm.get('RealtimeInterceptionTabName').toUpperCase(),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.RealTimeInterception,
        });
    (hasOnSiteConversion || isDataAndAnalyticsOpen) &&
        showTab(SETTINGS_TABS_NAMES.DataAndAnalytics, selectedLicense, isDataApiForExtendedResponseOn) &&
        userRole !== 'user' &&
        tabs.push({
            Panel: <DataAndAnalytics />,
            dataTestSelector: 'dataAndAnalytics',
            label: cm.get('DataApiTabName'),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.DataAndAnalytics,
        });
    isGaIntegrationOn &&
        showTab(SETTINGS_TABS_NAMES.GoogleAnalytics, selectedLicense, isDataApiForExtendedResponseOn) &&
        tabs.push({
            Panel: <GoogleAnalytics cm={cm} />,
            dataTestSelector: 'dataAndAnalytics',
            label: cm.get('GoogleAnalyticsTabName'),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.GoogleAnalytics,
        });
    isHubSpotFeatureOn &&
        showTab(SETTINGS_TABS_NAMES.HubSpot, selectedLicense, isDataApiForExtendedResponseOn) &&
        tabs.push({
            Panel: <HubSpot />,
            dataTestSelector: 'hubSpotFeature',
            label: cm.get('HubSpotTabName').toUpperCase(),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.HubSpot,
        });
    isMarketo &&
        showTab(SETTINGS_TABS_NAMES.Marketo, selectedLicense, isDataApiForExtendedResponseOn) &&
        tabs.push({
            Panel: <Marketo />,
            dataTestSelector: 'marketoFeature',
            label: cm.get('MarketoTabName').toUpperCase(),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.Marketo,
        });

    if (isAdobe && showTab(SETTINGS_TABS_NAMES.AdobeAnalytics, selectedLicense, isDataApiForExtendedResponseOn))
        tabs.push({
            Panel: <AdobeAnalytics />,
            dataTestSelector: 'adobeAnalyticsFeature',
            label: cm.get('AdobeAnalytics.tabName').toUpperCase(),
            perm: '',
            tabName: SETTINGS_TABS_NAMES.AdobeAnalytics,
        });

    return tabs;
};

export default Management;
