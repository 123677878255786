import moment from 'moment';

const ONE_BILLION = 1000000000,
    ONE_MILLION = 1000000,
    ONE_THOUSAND = 1000;

const createTableHeader = columns =>
    Object.entries(columns).map(([label, title]) => {
        return {
            label,
            title,
        };
    });

const isEllipsisActive = (e = {}) => {
    const current = e?.current || e;
    return current?.offsetHeight < current?.scrollHeight || current?.offsetWidth < current?.scrollWidth;
};

const LEAD_PROTECTION = 'sign-up-and-lead-protection';
export const CAS_ROUTE = 'customer-acquisition-security';
export const OSS_ROUTE = 'on-site-security';
export const CHEQ_ACQUISITION_ROUTE = 'cheq-acquisition';
export const MIS_ROUTE = 'marketing-intelligence-security';
export const CHEQ_ANALYTICS_ROUTE = 'cheq-analytics';
export const CHEQ_SLP_ROUTE = 'cheq-form-guard';
export const CHEQ_DEFEND = 'cheq-defend';
export const CHEQ_PRIVACY = 'privacy-compliance-enforcement';

const capitalizeWord = s => s.at(0).toUpperCase() + s.slice(1).toLowerCase();

const capitalizeSentence = sentence => sentence.split(' ').filter(Boolean).map(capitalizeWord).join(' ');

const isAllCapitals = s => s === s.toUpperCase();

const getSubDomain = () => window.location.host.split('.')[0];

const dateRange = [
    {
        label: 'Today',
        value: 'today',
        details: { type: 'days', range: 0 },
    },
    {
        label: 'Yesterday',
        value: 'yesterday',
        details: { type: 'days', range: 1 },
    },
    {
        label: 'Last7Days',
        value: 'last7Days',
        details: { type: 'days', range: 7 },
    },
    {
        label: 'Last30Days',
        value: 'last30Days',
        details: { type: 'days', range: 30 },
    },
    {
        label: 'Last3Months',
        value: 'last3Months',
        details: { type: 'months', range: 3 },
    },
    {
        label: 'Last6Months',
        value: 'last6Months',
        details: { type: 'months', range: 6 },
    },
    {
        label: 'Custom',
        value: 'Custom',
    },
];

const parseDate = ({ type, range }) => {
    let startDate;
    let endDate;
    if (type === 'months') {
        startDate = new Date(moment().subtract(range, 'month').startOf('month').startOf('day').format());
        endDate = new Date(moment().subtract(1, 'month').endOf('month').endOf('day').format());
    } else {
        startDate = new Date(moment().subtract(range, type).startOf('D').format());
        endDate = range <= 1 ? new Date(moment().subtract(range, type).endOf('D').format()) : new Date(moment().subtract(1, 'd').endOf('D').format());
    }
    return [startDate, endDate];
};

const convertDateToUnix = format => {
    let [startDate, endDate] = format;

    startDate = new Date(startDate);
    const userTimezoneOffset = startDate.getTimezoneOffset() * 60000;
    startDate = startDate.getTime() - userTimezoneOffset;

    endDate = new Date(endDate);
    endDate = endDate.getTime() - userTimezoneOffset;

    return { startDate, endDate };
};

export const convertDateOWithOffset = date => {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    const utc = d.getTime();
    const nd = new Date(utc + 3600000 * offset);
    return nd;
};

const getPercentage = (value, total, round = false) => {
    if (isNaN(value) || isNaN(total)) return 0;
    const result = ((value / total) * 100).toLocaleString(undefined, { maximumFractionDigits: 2 });
    if (isNaN(result)) return 0;
    return round ? Math.round(result) : result;
};

const formatToK = (num = 0) => {
    let tok = 0;
    switch (true) {
        case num >= ONE_BILLION: {
            tok = toLocaleString(num / ONE_BILLION) + 'B';
            break;
        }
        case num >= ONE_MILLION: {
            tok = toLocaleString(num / ONE_MILLION) + 'M';
            break;
        }
        case num >= ONE_THOUSAND: {
            tok = toLocaleString(num / ONE_THOUSAND) + 'K';
            break;
        }
        default: {
            tok = num;
        }
    }
    return tok;
};

// const isValidURL = str => {
//     var pattern = new RegExp(
//         '^(https?:\\/\\/)?' + // protocol
//             '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
//             '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
//             '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
//             '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
//             '(\\#[-a-z\\d_]*)?$',
//         'i'
//     ); // fragment locator
//     return !!pattern.test(str);
// };

function isValidURL(str) {
    var pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return !!pattern.test(str);
}

const sortBy = (field, data) => data.sort((a, b) => b[field] - a[field]);
const toLocaleString = number => (!isNaN(number) ? Number(number).toLocaleString(undefined, { maximumFractionDigits: 1 }) : 'Not Valid');
const parsePermissions = (permissions = []) => permissions.reduce((total, { model, action }) => ({ ...total, [model]: { ...total[model], [action]: true } }), {});

const MAP_PRODUCT_ID_TO_PATH = {
    1: `/${CHEQ_ACQUISITION_ROUTE}/dashboard`,
    2: `/${CHEQ_DEFEND}/dashboard`,
    3: `/${CHEQ_ANALYTICS_ROUTE}/dashboard`,
    // 5: '/ensighten/login?cheq=true',
    5: `/${CHEQ_PRIVACY}/dashboard`,
    8: `/${LEAD_PROTECTION}/dashboard`,
};

/**
 * @param {string} word
 * @returns {string}
 */
export const pascalCaseWord = word => {
    if (!word?.length) return word;
    if (word.length === 1) return word.toUpperCase();
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
};

/**
 * @function
 * @template TInput
 * @param {TInput} obj
 * @returns {Array<keyof TInput>}
 */
export const getObjectKeys = (obj = {}) => Object.keys(obj);

/**
 *
 * @param {unknown} thing
 * @returns {boolean}
 */
export const isNullish = thing => thing === undefined || thing === null;

export {
    createTableHeader,
    parsePermissions,
    isEllipsisActive,
    capitalizeWord,
    capitalizeSentence,
    isAllCapitals,
    getSubDomain,
    dateRange,
    parseDate,
    convertDateToUnix,
    getPercentage,
    formatToK,
    toLocaleString,
    sortBy,
    isValidURL,
    MAP_PRODUCT_ID_TO_PATH,
    LEAD_PROTECTION,
};

export const htmlDecode = str => {
    try {
        // for URIError: URI malformed errors
        return decodeURIComponent(str);
    } catch (e) {
        const fallback = str.replace(/%20/g, ' ');
        return fallback;
    }
};

export const capitalizeString = text => (text ? text.charAt(0).toUpperCase() + text.slice(1) : '');
